import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../services/message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	private whiteList = [
		'https://backoffice.move.butec.com.br/json_client/listarCep.php',
		'https://backoffice.move.butec.com.br/json_client/verificarData.php',
		'https://backoffice.move.butec.com.br/json_client/cadastrarContrato.php',
		'https://backoffice.move.butec.com.br/json_client/dadosCupom.php',
	];

	constructor(private messageService: MessageService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const containsInWhiteList = this.whiteList.includes(request.url);

		return next.handle(request).pipe(
			catchError((err: HttpErrorResponse) => {
				if (containsInWhiteList) {
					return throwError(request);
				}

				switch (err.status) {
					case 0:
						this.handle0();
						break;
					default:
						this.genericError(err);
						break;
				}

				return throwError(request);
			})
		);
	}

	private handle0() {
		this.messageService.toastDanger('Sem conexão com o servidor MOVETOGO. :(');
	}

	private genericError(errorResponse: HttpErrorResponse) {
		if (errorResponse?.error?.title === 'Fail') {
			this.messageService.toastDanger(errorResponse.error.message);
			return;
		} else if (errorResponse?.error?.cupom?.mensagem) {
			this.messageService.toastDanger(errorResponse.error.cupom.mensagem);
			return;
		}
		this.messageService.toastDanger(`Erro ${errorResponse.status} - Aconteceu algo inesperado. :(`);
	}
}
